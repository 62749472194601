import gql from 'graphql-tag'

export default gql`
  fragment checkoutPaymentPreferences on Preference {
    _id
    paymentType
    cashAmount
    nationalId
    otherPaymentType
    cardId
    card {
      _id
      brandId
      last4
      brandName
      paymentType
      cardType
    }
    store {
      _id
      availablePaymentTypes
      hideTip
    }
    website {
      _id
      otherPaymentTypes
    }
    user {
      _id
      cards {
        _id
        brandId
        brandName
        last4
        paymentType
        cardType
      }
    }
  }
`
