import cartItemFragment from '@data/fragments/cartFragment/cartItemFragment.main'
import gql from 'graphql-tag'

export default gql`
  fragment checkoutResumePreferences on Preference {
    _id
    deliveryType
    channel
    address {
      _id
    }
    time
    paymentType
    tipAmount
    tipPercentage
    cashAmount
    nationalId
    otherPaymentType
    menuId
    bagsRequired
    store {
      _id
      hasDelayInAcceptingOrders
      activeAlertMessage
      activeAlertClosedMessage
      hideTip
    }
    website {
      _id
      noTipByDefault
    }
    cardId
    couponCode
    justoCoinsToSpend
    cart {
      specialRequirementsIds
      deliveryType
      deliveryFee
      serviceFee
      hasDynamicDeliveryFee
      menuId
      itemsPrice
      acceptsTip
      hasFixedAmountTips
      tipOptions
      tipPercentagesOptions
      calculatedTipAmount
      originalItemsPrice: itemsPrice(includeDiscount: false)
      totalPrice
      amountToPay
      bagAmount
      maxBagsAllowed
      pointsDetails {
        amount
        name
      }
      couponStatus {
        couponId
        code
        errorMessage
        name
        discount
      }
      loyaltyTransactionStatus {
        transactionId
        loyaltyName
        discount
      }
      items {
        ...resumeCartItem
      }
      benefits
    }
    reducedDemand {
      enabled
      message
    }
  }
  ${cartItemFragment}
`
