import gql from 'graphql-tag'

export default gql`
  fragment checkoutProfilePreferences on Preference {
    _id
    sessionEmail
    user {
      _id
      name
      email
      phone
      profile {
        firstName
        lastName
      }
    }
  }
`
