import React, {useState} from 'react'
import SelectProduct from '@page-components/Order/Products/ProductsContent/SelectProduct'

import {ItemCartDetails} from '../../hooks/useCartDetails.main'

import styles from './styles.module.css'

export default function Update(props: {item: ItemCartDetails}) {
  const {item} = props
  const [open, setOpen] = useState(false)

  return (
    <>
      <SelectProduct
        product={item.product}
        open={open}
        cartItem={item}
        close={() => setOpen(false)}
      />
      <div
        className={styles.container}
        onClick={() => {
          setOpen(true)
        }}
        role="button"
        aria-label="update_cart_item"
        id="update_cart_item_button"
        // @ts-expect-error ts-migrate(2322) FIXME: Type '{ children: string; className: string; onCli... Remove this comment to see the full error message
        name="update_cart_item_button">
        Editar
      </div>
    </>
  )
}
