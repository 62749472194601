import gql from 'graphql-tag'

export default gql`
  fragment checkoutOrderParamsPreferences on Preference {
    _id
    orderParams
    website {
      _id
      menuConfiguration {
        orderParamsSchema
      }
    }
  }
`
