import gql from 'graphql-tag'

import billingFragment from './billingFragment'
import deliveryFragment from './deliveryFragment'
import orderParamsFragment from './orderParamsFragment'
import paymentFragment from './paymentFragment'
import profileFragment from './profileFragment'
import resumeFragment from './resumeFragment'

export default gql`
  fragment checkoutPreferences on Preference {
    _id
    websiteId
    storeId
    deliveryType
    menuId
    sessionEmail
    sessionEmailHasAccount
    sessionEmailHasName
    sessionPhone
    chatCommerceType
    loyaltyProgram
    ...checkoutResumePreferences
    ...checkoutDeliveryPreferences
    ...checkoutPaymentPreferences
    ...checkoutProfilePreferences
    ...checkoutOrderParamsPreferences
    ...checkoutBillingPreferences
  }

  ${resumeFragment}
  ${deliveryFragment}
  ${paymentFragment}
  ${profileFragment}
  ${orderParamsFragment}
  ${billingFragment}
`
