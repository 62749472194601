import gql from 'graphql-tag'

export default gql`
  fragment checkoutBillingPreferences on Preference {
    _id
    billing
    website {
      _id
      billingParamsSchema
    }
  }
`
