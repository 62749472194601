import React, {useEffect, useState} from 'react'
import Button from '@components/Button'
import DeliveryWizard from '@components/DeliveryWizard'
import SafeSuspenseLoading from '@components/SafeSuspenseLoading'
import {useUserPreferences} from '@data/queries/preferences/userPreferences.main'
import {EVENTS} from '@helpers/analytics/events'
import {PurchaseTaxonomies} from '@helpers/analytics/events/purchase'
import {sendEvent} from '@helpers/analytics/sendEvents'
import useEventsBaseProperties from '@hooks/useEventsBaseProperties'
import {useCartLoading} from '@page-components/Order/CartLoadingContext'
import useLayout from '@page-components/Order/useLayout'
import {useTranslation} from 'next-i18next'

import useCartDetails from './hooks/useCartDetails.main'
import Item from './Item'
import Resume from './Resume'
import useWhatsAppCommerceNumber from './useWhatsAppCommerceNumber'

import styles from './styles.module.css'

export default function Content() {
  const layout = useLayout()
  const userPrefs = useUserPreferences()
  const data = {preferences: userPrefs}
  const cart = useCartDetails()
  const {storeId} = data?.preferences ?? {}
  const [showCart, setShowCart] = useState(layout === 'v2' && !storeId ? false : true)
  const {t} = useTranslation()
  const i18nWebsite = 'website:order.cart'
  const i18nGeneric = 'generic:fields'
  const whatsappChatCommerceNumber = useWhatsAppCommerceNumber()
  const {loading} = useCartLoading()
  const baseEventProperties = useEventsBaseProperties()
  let productAmount = 0
  let subTotalPrice = 0
  cart?.items?.forEach(item => {
    productAmount += item.amount
    subTotalPrice += item.unitPrice * item.amount
  })

  useEffect(() => {
    if (!data?.preferences?._id) return

    sendEvent<PurchaseTaxonomies['cartPageViewed']>(EVENTS.purchase.cartPageViewed, {
      ...baseEventProperties,
      cartItemsAmount: cart?.items?.length ?? 0,
      productAmount,
      subTotalPrice,
      isScheduledOrder: data?.preferences?.time !== 'now',
      menuId: data?.preferences?.menuId ?? ''
    })
  }, [data?.preferences?._id])

  if (!cart) return null

  if (!showCart) {
    return (
      <div className={styles.optionsContent}>
        <SafeSuspenseLoading>
          <DeliveryWizard.AsComponent />
        </SafeSuspenseLoading>
        <div className={styles.buttonContinue}>
          <Button disabled={!storeId} onClick={() => setShowCart(true)} primary>
            {t(`${i18nGeneric}.continue`)}
          </Button>
        </div>
      </div>
    )
  }

  if (cart.items.length === 0) {
    return <div className={styles.empty}>{t(`${i18nWebsite}.emptyCart`)}</div>
  }

  if (showCart) {
    return (
      <div className={styles.container}>
        <div className={styles.items}>
          {loading ? (
            <div className="flex animate-pulse px-4">
              <div className="h-20 w-full bg-gray-200 rounded"></div>
            </div>
          ) : (
            cart.items.map(item => (
              <Item
                key={item._id}
                item={item}
                readOnly={false}
                amountClassname={''}
                menuId={data?.preferences?.menuId ?? ''}
              />
            ))
          )}
        </div>
        {cart.items.length > 5 ? <div className={styles.shadow} /> : null}
        <Resume whatsappChatCommerceNumber={whatsappChatCommerceNumber} />
      </div>
    )
  }
}
