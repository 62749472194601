import React from 'react'

import {ItemCartDetails} from '../../hooks/useCartDetails.main'

import styles from './styles.module.css'

export default function Options(props: {item: ItemCartDetails}) {
  if (!props.item.descriptionItems) {
    return (
      // @ts-expect-error ts-migrate(2322) FIXME: Type '{ children: any; className: string; numberOf... Remove this comment to see the full error message
      <span className={styles.options} numberOfLines={30}>
        {props.item.description}
      </span>
    )
  }

  return (
    <div className={styles.selectionItems}>
      {props.item.descriptionItems.map((item, index) => {
        const {title, selections} = item
        return (
          <div key={index} className={styles.item}>
            <div className={styles.title}>{title}</div>
            {selections.map((selection, index) => (
              <div className={styles.selectionText} key={index}>
                - {selection}
              </div>
            ))}
          </div>
        )
      })}
    </div>
  )
}
