import React, {useEffect} from 'react'
import fragment from '@data/fragments/checkout'
import useMessage from '@hooks/useMessage'
import useWebsiteId from '@hooks/useWebsiteId'
import {useMutate} from 'apollo-hooks'
import gql from 'graphql-tag'
import range from 'lodash/range'

import styles from './styles.module.css'

export default function Quantity(props: {
  bagsRequired?: number
  maxBagsAllowed?: number
  calculatedBagsRequired?: number
}) {
  const showMessage = useMessage()
  const mutate = useMutate()
  const websiteId = useWebsiteId()
  const {bagsRequired, maxBagsAllowed, calculatedBagsRequired} = props
  const value = bagsRequired || calculatedBagsRequired || 1

  useEffect(() => {
    if (!value || !maxBagsAllowed) return

    if (value > maxBagsAllowed) updateBagsRequiredPreferences(maxBagsAllowed)
  }, [value, maxBagsAllowed])

  const updateBagsRequiredPreferences = async value => {
    try {
      await mutate({
        mutation: gql`
          mutation setBagsRequiredPreferences($websiteId: ID, $bagsRequired: Float) {
            setUserPreferences(websiteId: $websiteId, bagsRequired: $bagsRequired) {
              ...checkoutPreferences
            }
          }
          ${fragment}
        `,
        variables: {websiteId, bagsRequired: Number(value)}
      })
    } catch (error) {
      showMessage(error)
    }
  }

  const onChange = event => {
    updateBagsRequiredPreferences(event.target.value)
  }
  const renderOptions = () => {
    return range(1, maxBagsAllowed + 1).map(amount => {
      return (
        <option key={amount} value={String(amount)}>
          {amount}
        </option>
      )
    })
  }
  return (
    <div className={styles.container}>
      <select className={styles.select} onChange={onChange} value={String(value)}>
        {renderOptions()}
      </select>
    </div>
  )
}
