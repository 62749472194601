import Button from '@components/Button'
import Bags from '@components/Money/Bags'
import {EVENTS} from '@helpers/analytics/events'
import {PurchaseTaxonomies} from '@helpers/analytics/events/purchase'
import {sendEvent} from '@helpers/analytics/sendEvents'
import deliveryFeeText from '@helpers/misc/deliveryFeeText'
import useTrackEcommerceEvent, {ECOMMERCE_EVENTS} from '@helpers/track/useTrackEcommerceEvent'
import useEventsBaseProperties from '@hooks/useEventsBaseProperties'
import useIsSpecialLayout from '@hooks/useIsSpecialLayout'
import formatNumber from '@i18n/formatNumber'
import FormatNumber from '@i18n/formatNumber/Component'
import useInitialData from '@page-components/Layout/useInitialData'
import WarningBox from '@page-components/Order/warningBox'
import classnames from 'classnames'
import {useTranslation} from 'next-i18next'
import dynamic from 'next/dynamic'
import {useRouter} from 'next/router'
import {useEffect, useState} from 'react'

import isLoginWithExternalProvider, {
  loginExternalProviderPath,
} from '@helpers/auth/loginWithExternalProvider'
import useIsAnalyticsV2Avail from '@page-components/AnalyticsV2/hooks/useIsAnalyticsV2Available'
import useLazyAnalyticsV2 from '@page-components/AnalyticsV2/hooks/useLazyAnalyticsV2'
import {INTERNAL_EVENTS} from '@page-components/AnalyticsV2/types/analyticsServicesTypes'
import CartShowTerms from '@page-components/Order/CartV3/CartContent/CartButton/CartShowTerms'
import usePreferencesForResume from './hooks/usePreferencesForResume.main'
import styles from './styles.module.css'

const DynamicDelivery = dynamic(() => import('@components/Money/DynamicDelivery'))

export interface Props {
  whatsappChatCommerceNumber?: string
}

export default function Resume(props: Readonly<Props>) {
  const {whatsappChatCommerceNumber} = props
  const {t} = useTranslation()
  const i18nWebsite = 'website:order.cart.content.resume'
  const i18nGeneric = 'generic:fields'
  const i18nProduct = 'order.products.productsContent.selectProduct'
  const router = useRouter()
  const isSpecialLayout = useIsSpecialLayout()
  const [redirecting, setRedirecting] = useState(false)
  const {website} = useInitialData()
  const checkoutPath = '/checkout'
  const baseEventProperties = useEventsBaseProperties()
  const analyticsV2 = useLazyAnalyticsV2()
  const isAnalyticsV2Avail = useIsAnalyticsV2Avail()

  useEffect(() => {
    router.prefetch(checkoutPath)
  }, [router, checkoutPath])

  const preferences = usePreferencesForResume()

  const {cart, address, menuId, userId} = preferences
  const {itemsPrice, realPrice, totalPrice, bagAmount, items, deliveryType, storeId} = cart ?? {}
  let productAmount = 0
  items?.forEach(item => {
    productAmount += item.amount
  })

  const trackEcommerceEvent = useTrackEcommerceEvent()

  const redirectToLoginWithExternalProvider =
    !userId && isLoginWithExternalProvider(website?.loginWithExternalProvidersAvailable)

  const goToReview = () => {
    setRedirecting(true)
    if (isAnalyticsV2Avail) {
      analyticsV2.trackEventAsDefaultOnInternal(INTERNAL_EVENTS.continueToCheckout)
    } else {
      trackEcommerceEvent(ECOMMERCE_EVENTS.INITCHECKOUT, {cart})
      sendEvent<PurchaseTaxonomies['continueToCheckout']>(EVENTS.purchase.continueToCheckout, {
        ...baseEventProperties,
        subTotalPrice: itemsPrice ?? 0,
        cartitemsAmount: items.length ?? 0,
        productAmount,
        menuId: menuId ?? '',
      })
    }
    router.push(redirectToLoginWithExternalProvider ? loginExternalProviderPath : checkoutPath)
  }

  const goToWhatsapp = () => {
    setRedirecting(true)
    if (isAnalyticsV2Avail) {
      analyticsV2.trackEventAsDefaultOnInternal(INTERNAL_EVENTS.continueToCheckout)
    } else {
      trackEcommerceEvent(ECOMMERCE_EVENTS.INITCHECKOUT, {cart})
      sendEvent<PurchaseTaxonomies['continueToCheckout']>(EVENTS.purchase.continueToCheckout, {
        ...baseEventProperties,
        subTotalPrice: itemsPrice ?? 0,
        cartitemsAmount: items.length ?? 0,
        productAmount,
        menuId: menuId ?? '',
      })
    }

    if (!whatsappChatCommerceNumber) {
      const justoBotPhoneNumber = '18453032928' // TODO: CRUD and fetch from backend
      router.push(`/whatsapp-redirect?phone=${justoBotPhoneNumber}&text=Completar`)
      return
    }

    router.push(
      `/whatsapp-redirect?phone=${whatsappChatCommerceNumber.replace('+', '')}&text=Completar`,
    )
  }

  const hasAnyOutOfStock = () => {
    for (const item of items) {
      if (item.isOutOfStock) return true
    }
    return false
  }

  const amountToReachMinimum = () => {
    if (!website) return
    if (!website.minimumOrderPrice) return
    if (!itemsPrice) return
    const amount = website.minimumOrderPrice - itemsPrice
    if (amount > 0) return amount
    return 0
  }

  const renderDiscount = () => {
    if (itemsPrice === realPrice) return

    return (
      <div className={styles.discount}>
        <div className={styles.label}>{t(`${i18nWebsite}.labelDiscount`)}</div>
        <div>
          - <FormatNumber value={realPrice - itemsPrice} />{' '}
        </div>
      </div>
    )
  }

  const renderSubTotal = () => {
    return (
      <div className={styles.itemsPrice}>
        <div className={styles.label}>{t(`${i18nWebsite}.labelProductsTotal`)}</div>
        <div>{formatNumber(realPrice, 'money')}</div>
      </div>
    )
  }

  const renderDeliveryFee = () => {
    if (deliveryType !== 'delivery') return null
    if (!storeId) return null
    const {deliveryFee} = cart

    const dynamicFee = preferences && preferences?.store?.currentExtraDeliveryCost > 0

    const deliveryContent = deliveryFeeText({address, deliveryFee})

    return (
      <div className={styles.itemsPrice}>
        <div className={styles.label}>{t(`${i18nGeneric}.dispatch`)}</div>
        <div>{dynamicFee ? <DynamicDelivery value={deliveryContent} /> : deliveryContent}</div>
      </div>
    )
  }

  const renderButton = () => {
    const disabled = hasAnyOutOfStock()
    if (disabled) {
      return (
        <div className={styles.button}>
          <Button primary disabled>
            {t(`${i18nWebsite}.labelProductsOutStock`)}
          </Button>
        </div>
      )
    }

    const amountToReach = amountToReachMinimum()

    if (amountToReach) {
      return (
        <div className={styles.button}>
          <Button primary disabled>
            {t(`${i18nWebsite}.labelNotReachedMin`)}
          </Button>
          <div className="description" style={{marginTop: 5}}>
            {t(`${i18nWebsite}.labelAddMoreProducts`, {
              money: formatNumber(amountToReach, 'money'),
            })}
          </div>
        </div>
      )
    }

    if (isSpecialLayout === 'chat') {
      return (
        <div className={classnames(styles.button, styles.whatsappButton)}>
          <Button primary onClick={goToWhatsapp} loading={redirecting}>
            {t(`${i18nWebsite}.labelCompleteOrderInWhatsapp`)}
          </Button>
        </div>
      )
    }

    return (
      <div className={styles.button}>
        {!preferences?.store ? (
          <WarningBox>{t(`${i18nProduct}.inner.actions.labelAddAddressOrSelectStore`)}</WarningBox>
        ) : null}
        <CartShowTerms website={website} userId={preferences?.userId} />
        <Button primary onClick={goToReview} loading={redirecting} disabled={!preferences?.store}>
          {t(`${i18nWebsite}.labelContinueWithMyOrder`)}
        </Button>
      </div>
    )
  }

  return (
    <div className={styles.container}>
      {renderSubTotal()}
      {renderDeliveryFee()}
      {renderDiscount()}
      <Bags
        deliveryType={preferences.deliveryType}
        bagAmount={preferences.cart?.bagAmount}
        bagsRequired={preferences.bagsRequired}
        calculatedBagsRequired={preferences.cart?.calculatedBagsRequired}
        maxBagsAllowed={preferences.cart?.maxBagsAllowed}
      />
      <div className={styles.total}>
        <div className={styles.label}>{t(`${i18nWebsite}.labelTotal`)}</div>
        <div>
          <FormatNumber value={totalPrice + bagAmount} />{' '}
        </div>
      </div>
      {renderButton()}
    </div>
  )
}
