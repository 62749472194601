import React, {useCallback, useState} from 'react'
import {GetCartDetailsQuery} from '@data/__generated__/types.main'
import {useUpdateItemQuantity} from '@data/mutations/cart/updateItemQuantity.preferences'
import useMessage from '@hooks/useMessage'
import get from 'lodash/get'
import isString from 'lodash/isString'
import range from 'lodash/range'

import styles from './styles.module.css'

const MAX_SELECT_AMOUNT = 20
const MORE_SELECT_AMOUNT = MAX_SELECT_AMOUNT + 1
export interface AmountProps {
  item: GetCartDetailsQuery['preferences']['cart']['items'][0]
  readOnly: boolean
  amountClassname: string
  refetchCart?: () => Promise<void>
  sendEvent?: any
}

export default function Amount(props: AmountProps) {
  const {item} = props
  const showMessage = useMessage()
  const {updateItemQuantity: updateCartItemAmount} = useUpdateItemQuantity()
  const [state, setState] = useState({inputState: String(item.amount)})

  const update = useCallback(
    async amount => {
      try {
        await updateCartItemAmount({cartItemId: item._id, quantity: amount})
        props.sendEvent?.({cartItemId: item._id, amount})
      } catch (error) {
        showMessage(error)
      }
    },
    [item?._id]
  )

  const onChange = useCallback(
    event => {
      const amount = Number(event.target.value)
      update(amount)
      setState({inputState: String(amount)})
    },
    [update]
  )

  const renderOptions = () => {
    const maxPurchaseQuantity = get(props, 'item.product.maxPurchaseQuantity')
    const rangeMax =
      maxPurchaseQuantity && maxPurchaseQuantity < MORE_SELECT_AMOUNT
        ? maxPurchaseQuantity
        : MORE_SELECT_AMOUNT
    return range(0, rangeMax + 1).map(amount => {
      return (
        <option key={amount} value={String(amount)}>
          {amount === MORE_SELECT_AMOUNT ? 'Más' : amount}
        </option>
      )
    })
  }

  const renderSelect = () => {
    const {amount} = item
    if (amount && amount >= MORE_SELECT_AMOUNT) {
      return (
        <input
          type="text"
          value={isString(state.inputState) ? state.inputState : String(amount)}
          onChange={event => setState({inputState: event.target.value})}
          className={styles.biggerInput}
          onBlur={onChange}
        />
      )
    }
    return (
      <div className={styles.container}>
        <select className={styles.select} onChange={onChange} value={String(amount)}>
          {renderOptions()}
        </select>
      </div>
    )
  }

  const renderValue = () => {
    const {amount} = item
    const {amountClassname} = props
    if (amountClassname) {
      return <div className={amountClassname}>{amount}</div>
    }

    return <div>{amount}</div>
  }

  if (props.readOnly) {
    return renderValue()
  } else {
    return renderSelect()
  }
}
