import React from 'react'
import FormatNumber from '@i18n/formatNumber/Component'

import Quantity from './Quantity'

import styles from './styles.module.css'

export default function Bags(props: {
  deliveryType: string
  bagAmount?: number
  calculatedBagsRequired?: number
  maxBagsAllowed?: number
  bagsRequired?: number
}) {
  const {deliveryType, bagAmount} = props

  const renderLine = (label, value, style?) => (
    <div className={style || styles.line}>
      <div className={styles.label}>{label}</div>
      <div>{value}</div>
    </div>
  )

  if (!bagAmount) return null
  if (deliveryType !== 'go') return renderLine('Bolsas', <FormatNumber value={bagAmount} />)

  return (
    <div className={styles.container}>
      <div className={styles.head}>
        <div className={styles.quantity}>
          <Quantity
            bagsRequired={props.bagsRequired}
            calculatedBagsRequired={props.calculatedBagsRequired}
            maxBagsAllowed={props.maxBagsAllowed}
          />
        </div>
        <div className={styles.name}>Bolsas</div>

        <div>{<FormatNumber value={bagAmount} />}</div>
      </div>
    </div>
  )
}
