import {GetCartDetailsQuery} from '@data/__generated__/types.main'
import {EVENTS} from '@helpers/analytics/events'
import {PurchaseTaxonomies} from '@helpers/analytics/events/purchase'
import {sendEvent} from '@helpers/analytics/sendEvents'
import useAnalyticLocation from '@hooks/useAnalyticLocation'
import useEventsBaseProperties from '@hooks/useEventsBaseProperties'
import formatNumber from '@i18n/formatNumber'
import FormatNumber from '@i18n/formatNumber/Component'
import classnames from 'classnames'
import isEqual from 'lodash/isEqual'
import omit from 'lodash/omit'
import {TFunction, withTranslation} from 'next-i18next'
import React, {useMemo} from 'react'

import Amount from './Amount'
import Options from './Options'
import Update from './Update'
import useItemBenefits from './hooks/useItemBenefits.main'

import useIsAnalyticsV2Avail from '@page-components/AnalyticsV2/hooks/useIsAnalyticsV2Available'
import {useTrackProductEdit} from '@page-components/AnalyticsV2/tracking-hooks/useTrackProductEdit'
import styles from './styles.module.css'

export interface ItemProps {
  item: GetCartDetailsQuery['preferences']['cart']['items'][0] & {deletedAt?: string}
  readOnly?: boolean
  showOptions?: boolean
  smallPadding?: boolean
  amountClassname?: string
  menuId?: string
  t: TFunction
}

export const Item: React.FC<ItemProps> = props => {
  const {showOptions = true, smallPadding = false} = props
  const benefits = useItemBenefits()
  const eventBaseProperties = useEventsBaseProperties()
  const actionLocation = useAnalyticLocation('editProduct')
  const trackProductEdit = useTrackProductEdit()
  const isAnalyticsV2Avail = useIsAnalyticsV2Avail()

  const getBenefitByItem = useMemo(() => {
    const {item} = props
    const itemModifiers = item.modifiers?.length
      ? item.modifiers.map(modifier => omit(modifier, ['__typename']))
      : []

    return benefits?.find(({productId, item: benefitItem}) => {
      return productId === item?.product?._id && isEqual(itemModifiers, benefitItem.modifiers)
    })
  }, [props])

  const sendEditAmountEvent = ({amount}) => {
    if (!isAnalyticsV2Avail) {
      sendEvent<PurchaseTaxonomies['productEdited']>(EVENTS.purchase.productEdited, {
        ...eventBaseProperties,
        productEditAction: amount === 0 ? 'delete' : 'edit',
        productId: props.item?.product?._id ?? '',
        productAmount: amount,
        isAmountEdited: true,
        isOutOfStock: props.item?.isOutOfStock,
        menuId: props.menuId,
        actionLocation: actionLocation,
      })
    } else {
      trackProductEdit.track({item, menuId: props.menuId})
    }
  }

  const renderPrice = () => {
    const {unitPrice, realPrice, amount} = props.item
    const benefit = getBenefitByItem

    if (unitPrice === null) return 'No disponible'
    if (realPrice === unitPrice && !benefit) {
      return <FormatNumber value={unitPrice * amount} />
    }
    if (realPrice !== unitPrice && !benefit) {
      return (
        <span>
          <span className={styles.realPrice}>{formatNumber(realPrice * amount, 'money')}</span>
          <FormatNumber value={unitPrice * amount} />
        </span>
      )
    }
    if (benefit?.totalPriceWithBenefit) {
      return (
        <span>
          <span className={styles.realPrice}>{formatNumber(realPrice * amount, 'money')}</span>
          <FormatNumber value={benefit?.totalPriceWithBenefit} />
        </span>
      )
    }
  }

  const {readOnly, item, amountClassname} = props
  const {product, deletedAt} = item
  const isLoading = item?._id?.startsWith('pending')
  const containerStyle = smallPadding ? styles.smallPaddingContainer : styles.container

  return (
    <div className={isLoading ? styles.loading : containerStyle}>
      <div className={classnames(styles.head, deletedAt && styles.deleted)}>
        <div className={styles.amount}>
          <Amount
            item={props.item}
            readOnly={readOnly}
            amountClassname={amountClassname}
            sendEvent={sendEditAmountEvent}
          />
        </div>
        <div className={styles.name}>
          {product.name}
          {deletedAt && (
            <div className={styles.deletedAt}>{props.t('fields.deleted', {ns: 'generic'})}</div>
          )}
        </div>

        <div className={styles.price}>{renderPrice()}</div>
      </div>
      {showOptions && (
        <div className={styles.bottom}>
          <Options item={props.item} />
          {readOnly ? null : (
            <div className={styles.editContainer}>
              <Update item={props.item} />
              {/* <Delete item={props.item} /> */}
            </div>
          )}
        </div>
      )}
      {item.outOfStockMessage ? (
        <div
          className={styles.outOfStock}
          dangerouslySetInnerHTML={{__html: item.outOfStockMessage}}
        />
      ) : null}
    </div>
  )
}

export default withTranslation()(Item)
