import timeFieldPreferencesFragment from '@data/fragments/preferences/timeFieldPreferencesFragment.main'
import updateAddressFragment from '@data/fragments/preferences/updateAddressFragment.main'
import gql from 'graphql-tag'

export default gql`
  fragment checkoutDeliveryPreferences on Preference {
    _id
    websiteId
    deliveryType
    tableName
    timeLabel
    time
    placeId
    itsStoreOpenAtTime
    dropOffType
    itsStoreOpenAtTime
    directions {
      duration
    }
    website {
      _id
      name
    }
    address {
      _id
      placeId
      address
      addressSecondary
      addressLine2
      acceptsNoLine2
      location
      comment
    }
    store {
      _id
      name
      acceptServeSchedulingOrders
      schedulingOrdersDays
      currentPreparationDuration
      currentExtraDeliveryCost
      currentDeliveryDuration
      isOverloaded
      address {
        address
        addressLine2
      }
    }
    cart {
      deliveryType
      deliveryFee
      serviceFee
    }
    ...timeFieldPreferences
    ...updateAddressFragment
  }
  ${timeFieldPreferencesFragment}
  ${updateAddressFragment}
`
