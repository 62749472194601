import useWebsiteId from '@hooks/useWebsiteId'
import {useApolloQuery} from 'apollo-hooks'
import gql from 'graphql-tag'

export default function useItemBenefits() {
  const websiteId = useWebsiteId()
  const {preferences} = useApolloQuery({
    query: gql`
      query getItemBenefits($websiteId: ID) {
        preferences: userPreferences(websiteId: $websiteId) {
          _id
          cart {
            benefits
          }
        }
      }
    `,
    variables: {websiteId},
    fetchPolicy: 'cache-first'
  })

  return preferences?.cart?.benefits || []
}
